import './Expences.css';
import ExpenceItems from './ExpenceItems';


function Expences(props){
    return (
        <div className='expences'>
            {
            
            props.item.map(
                thisvalue => (
                    <ExpenceItems
                    date={thisvalue.date}
                    title={thisvalue.title}
                    amount={thisvalue.amount} />
                )
            )

            }
                      
          
        </div>
    );
}

export default Expences;