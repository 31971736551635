import React , {useState} from "react";

import './ExpenceForm.css';

const ExpenceForm = (props) =>
{
    const [enteredtitle , setenterdtitle] = useState('');
    const [enteredamount , setenterdamount] = useState('');
    const [entereddate , setenterddate] = useState('');


    const titlechangehandler = (event) => {
        setenterdtitle(event.target.value);
    };
    const amountchangehandler = (event) => {
        setenterdamount(event.target.value)
    };
    const datechangehandler = (event) => {
        setenterddate(event.target.value)
    };
  

    const formsubmitt = (event) =>{
         
        event.preventDefault();
         
        const expencedetail = {
             title : enteredtitle,
             amount : enteredamount,
             date : new Date(entereddate)
        }
       
        props.this_a(expencedetail);


        setenterdtitle('');
        setenterdamount('');
        setenterddate('');
       
    }

    return (
        <form onSubmit={formsubmitt}>
            <div className="new-expence_controls">
                <div  className="new-expence_controls">
                    <label>Title</label>
                    <input type="text" value={enteredtitle} onChange={titlechangehandler} />
                </div>
                <div  className="new-expence_controls">  
                    <label>amount</label>
                    <input type="number"  value={enteredamount} min="0.01" step="0.01" onChange={amountchangehandler} />
                   
                </div>
                <div  className="new-expence_controls">
                    <label>date</label>
                    <input type="date" value={entereddate}  onChange={datechangehandler}/>
                   
                </div>
            </div>
            <div className="new-expence_actions">
                <button type="submit">Add expence</button>
            </div>
        </form>
    );
}

export default ExpenceForm;