import'./ExpenceItems.css';
import ExpenceDate from './ExpenceDate.js';



function ExpenceItems(props){
 
    return(

         <div className='ExpenceItem'>
          <ExpenceDate
           date = {props.date}
           />
           <div className='Expencedes'>
            <h2>{props.title}</h2>
            <div className='Expencecost'>
                ${props.amount}
            </div>
           </div>
         </div>
    );
}

export default ExpenceItems;