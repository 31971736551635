import React from "react";

import './NewExpence.css';

import ExpenceForm from './ExpenceForm'


const NewExpence = (props) => {
    const first_a = (thisdetail) => {
        
        let op = 200;

        const expencedetail = {
            ...thisdetail , 
            id : op
        }

        props.this_b(expencedetail);
       
        

    } 
    return (
        <div className="new-expence">
            <ExpenceForm  this_a = {first_a}/>
        </div>
    );

}

export default NewExpence;
