import './ExpenceDate.css';

function ExpenceDate(props){
    const month = props.date.toLocaleString('en-US', {month: 'long'});
    const year = props.date.getFullYear();
    const day = props.date.toLocaleString('en-US',{day: '2-digit'});
    return (
        <div className='expencedate' >
        <div className='expencedate_month' >{month}</div>
        <div className='expencedate_year' >{year}</div>
        <div className='expencedate_day' >{day}</div>
       </div>
    );
}

export default ExpenceDate;