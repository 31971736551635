
import { useState } from 'react';
import Expences from './components/Expences';
import NewExpence from './components/NewExpence/NewExpence';

let dummy = [
  {
    
    id: 'e1',
    date: new Date(2021 , 5 , 22),
    amount: 2000,
    title: "College Fee"

  },
  {
    
    id: 'e2',
    date: new Date(2021 , 5 , 1),
    amount: 500,
    title: "Fuel"

  },
  {
    
    id: 'e3',
    date: new Date(2021 , 1, 22),
    amount: 800,
    title: "GYM"
  }

]

function App() {

  const [expence , setexpence] = useState(dummy);

  const first_b = (recieved) =>{
        
    const temp = [ recieved , ...expence];
         setexpence(temp)
  }

  

  return (
    <div className="App">
      <NewExpence this_b = {first_b}/>
      <Expences item={expence}/>
    </div>
  );
}

export default App;
